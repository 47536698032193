.no-scroll {
  overflow: hidden; /* Prevents both vertical and horizontal scrolling */
  touch-action: none; /* Blocks touch gestures like swiping */
}

/* .modal-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 200vh;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10000;
  color: white;
  display: block;
} */

.carousel .control-next.control-arrow:before {
  border-left: 12px solid #fff !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.6;
}

.carousel .control-prev.control-arrow:before {
  border-right: 12px solid #fff !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 0px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

@media (max-width: 465px) {
  .carousel .control-next.control-arrow:before {
    border-left: 12px solid #000000 !important;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 12px solid #000000 !important;
  }

  .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    margin: 0 0px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }
}
