.glitchPilot {
  background: url("../assets");
  background-repeat: no-repeat;
  transform: scale(2);
  animation: glitchAnimation 8s infinite;
}

.parentContainerPilot {
  transform: scale(1.75);
  overflow: visible;
  position: relative;
  z-index: 1;
}

.animatePilot {
  animation: animate 0.5s infinite steps(1);
}

@keyframes animate {
  0%,
  100% {
    background-position: 0px 0px;
    transform: skewX(0deg);
    color: inherit;
  }
  4% {
    background-position: 0.1px 0px;
    transform: skewX(-0.1deg);
    opacity: 0.9;
  }
  8% {
    background-position: 0.1px 0px;
    transform: skewX(-0.1deg);
    /* opacity: 1; */
  }
  10% {
    background-position: 0.1 0px;
    transform: skewX(-0.1deg);
    /* opacity: 0.5; */
  }
  20% {
    background-position: -0.1 0px;
    transform: skewX(0.1eg);
    opacity: 1;
  }
  30% {
    background-position: 1px 0px;
    transform: skewX(-0.1deg);
  }
  40% {
    background-position: 0.1 0px;
    transform: skewX(0.21eg);
  }
  50% {
    background-position: -2px 0px;
    transform: skewX(-0.1deg);
  }
  60% {
    background-position: -2px 0px;
    transform: skewX(0.1deg);
  }
  70% {
    background-position: 0px -2px;
    transform: skewX(-0.1deg);
  }
  80% {
    background-position: -6px -2px;
    transform: skewX(0.1deg);
  }
  90% {
    background-position: 2px 1px;
    transform: skewX(-0.1deg);
  }
}
