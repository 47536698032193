@font-face {
  font-family: "Exo";
  src: url("../../public/fonts/Exo-VariableFont_wght.ttf") format("opentype");
}

.bottom-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to bottom, #ffffff, #90e2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
