/* @keyframes pulse {
  0% {
    transform: scale(2.8);
  }
  5% {
    transform: scale(2.9);
  }
  10% {
    transform: scale(3);
  }
  25% {
    transform: scale(3.1);
  }
  40% {
    transform: scale(3.2);
  }
  50% {
    transform: scale(3.1);
  }
  75% {
    transform: scale(3);
  }
  90% {
    transform: scale(2.9);
  }
  100% {
    transform: scale(2.8);
  }
}

.nebula {
  animation: pulse 2s infinite;
} */

@keyframes pulse {
  0% {
    transform: scale(1.7);
  }
  50% {
    transform: scale(1.9);
  }
  100% {
    transform: scale(1.7);
  }
}

.nebula {
  animation: pulse 5s infinite;
}

@font-face {
  font-family: "Exo";
  src: url("../../public/fonts/Exo-VariableFont_wght.ttf") format("opentype");
}

.ships-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to bottom, #d190fd, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
