@font-face {
  font-family: "Exo";
  src: url("../../public/fonts/Exo-VariableFont_wght.ttf") format("opentype");
}

.pilot-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to bottom, #ffffff, #d190fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.5; /* adjust this value as necessary */
}

.pilot-paragraph-container {
  /* width: 400px; */
  /* background: rgba(65, 13, 112, 0.5); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(11.5px); */
  /* -webkit-backdrop-filter: blur(11.5px); */
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}
