.App {
  /* text-align: center;
  color: white; */
  background-color: black;
}

.hero-container {
  position: relative;
  z-index: 100;
}

.nav-container {
  position: relative;
  z-index: 9999;
}

@font-face {
  font-family: "EXO";
  src: url("../public/fonts/Exo-VariableFont_wght.ttf");
}

/* .video-container {
  position: relative;
  overflow: hidden;
}
*/

#arcCanvas {
  width: 100%;
  height: 50px;
}

/*
.video-section {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
} */

.video-section {
  position: relative;
  width: 100%;
  height: auto;
  overflow-y: visible;
}

.arc-image {
  position: absolute;
  bottom: 0%; /* Adjust this value as needed */
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  transform: translateY(
    44%
  ); /* This will pull the image up by half its height */
}

.arc-image-wide {
  position: absolute;
  bottom: 0%; /* Adjust this value as needed */
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  transform: translateY(
    50.8%
  ); /* This will pull the image up by half its height */
}

.arc-blue-image {
  position: absolute;
  bottom: 0%; /* Adjust this value as needed */
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  transform: translateY(
    54%
  ); /* This will pull the image up by half its height */
}

/* Other styles remain the same */

.video-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Keep it behind the content */
}

.content-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

/* .banner-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to left, #ffffff, #ff19d1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

/* .banner-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to left, #ffffff, #ff19d1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex; 
  flex-direction: column; 
  align-items: center;
} */

.banner-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to left, #ffffff, #ff19d1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: grid; /* Switched to grid */
  place-items: left; /* Center content */
}

/* Add this if your container has a fixed width or you know the maximum width */
.banner-text-container > div {
  /* max-width: 800px; */
  width: 100%;
}

.info-banner {
  background-color: rgba(8, 8, 8, 1); /* Dark background with 50% opacity */
  color: white; /* Assuming you want white text */
  text-align: center; /* Center the text */
  max-width: 900px;
  margin: 0 auto; /* Center the banner horizontally */
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Exo", sans-serif;
  border-top: solid rgb(41, 40, 40);
  border-bottom: solid rgb(41, 40, 40);
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0)
  );
  position: absolute; /* or 'absolute' depending on your layout needs */
  left: 50%; /* Set left position to 50% of the parent element */
  transform: translateX(-50%); /* Shift the banner back by half its own width */
}

.info-banner-mobile {
  background-color: rgba(8, 8, 8, 1); /* Dark background with 50% opacity */
  color: white; /* Assuming you want white text */
  text-align: center; /* Center the text */
  width: 100%; /* Adjust the width as necessary */
  margin: 0 auto; /* Center the banner horizontally */
  top: 240px;
  border-top: solid rgb(41, 40, 40);
  border-bottom: solid rgb(41, 40, 40);
  padding: 20px; /* Add some padding */
  font-family: "Exo", sans-serif;
  margin-top: 40px;
  position: absolute; /* or 'absolute' depending on your layout needs */
  left: 50%; /* Set left position to 50% of the parent element */
  transform: translateX(-50%); /* Shift the banner back by half its own width */
  /* z-index: 10; */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5); /* Shadow for floating effect */
}

.go-mint-button {
  font-family: "Exo", sans-serif;
  background-color: #ff00ff;
  color: white;
  box-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
  border-radius: 8px;
  cursor: pointer;
  /* font-size: 25px; */
}

.go-mint-button:hover {
  background-color: #e600e6;
  box-shadow: 0 0 15px #e600e6, 0 0 25px #e600e6, 0 0 35px #e600e6;
}

.terms-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 800px) {
  .info-banner {
    margin-top: 20px;
    padding-top: 20px; /* Top padding */
    padding-bottom: 20px; /* Bottom padding */
  }
}
