.transition-opacity {
  transition: opacity 0.05s ease-in;
  transition: opacity .65s ease-out;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-100 {
  opacity: 1;
}