.parentContainerHorizon {
  transform: scale(1.7);
  position: relative;
  z-index: 1;
  /* overflow: visible; */
}

.glitch-container {
  position: relative;
  overflow: hidden;
}

.main-image {
  display: block;
  width: 100%;
  height: auto;
  z-index: 1;
}

.glitch-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2;
}

/* .glitch-image {
  flex: 1;
  width: 100%;
  height: auto;
  opacity: 0;
  animation: glitchAnimation 3s infinite;
}

@keyframes glitchAnimation {
  0% {
    opacity: 1;
  }
  10% {
    transform: translate(2px, -2px);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  30% {
    transform: translate(-2px, 0px);
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
} */
