@font-face {
  font-family: "Exo";
  src: url("../../public/fonts/Exo-VariableFont_wght.ttf") format("opentype");
}

.hero-text-container {
  font-family: "Exo", sans-serif;
  background: linear-gradient(to bottom, #ffffff, #ff19d1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-paragraph-container {
  background: rgba(63, 12, 111, 0.4);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 20px;
  /* border: 4px solid rgb(255, 255, 255); */
  /* box-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff; */
}
