.termsModal {
  width: 100%;
  color: white;
  font-weight: 300;
  background: rgb(0, 0, 0);
  font-family: "Exo", sans-serif;
  z-index: 10000;
}

/* For Webkit (Safari, Chrome, and Opera) */
.storySlide::-webkit-scrollbar,
.p-5::-webkit-scrollbar {
  display: none; /* This hides the scrollbar */
}

/* For Internet Explorer, Edge, and Firefox */
.storySlide,
.p-5 {
  -ms-overflow-style: none; /* for Internet Explorer and Edge */
  scrollbar-width: none; /* for Firefox */
}

.no-scroll {
  overflow: hidden; /* Prevents both vertical and horizontal scrolling */
  touch-action: none; /* Blocks touch gestures like swiping */
}
